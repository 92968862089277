import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';
import type { TeamMembersUser } from '../lib/types/TeamMembersUser';

export type TeamMembersQueryParams = {
  sort?: {
    property: 'department' | 'organizationdomainId' | 'jobtitle' | 'name' | 'cyberiq';
    sortDirection: 1 | -1;
  };
  page?: {
    skip: number;
    take: number;
  };
  filters?: {
    property: string;
    operator: string;
    value: string;
  }[];
};

export type TeamMembersQueryResponse = {
  total: number;
  skip: number | null;
  take: number | null;
  values: TeamMembersUser[];
};

export const useTeamMembersQuery = (params: TeamMembersQueryParams) => {
  return useLPSuspenseQuery<TeamMembersQueryResponse>(
    ['TeamMembersQuery', params],
    `/manager/teamLearners`,
    {},
    undefined,
    {
      method: 'POST',
      json: params,
    },
  );
};
