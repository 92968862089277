import { type FC, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Table } from '../Table';
import type { Column } from '../Table/Table';
import { Coin } from '../Props';
import { ActivityOverviewChart } from './components/ActivityOverviewChart/ActivityOverviewChart';
import { useLanguageContext } from '../../lib/hooks/useLanguageContext';
import {
  type GetLatestActivitiesResponse,
  type LatestActivitiesQueryParams,
  type LearnerActivity,
  trackedActionsScoreTypesSet,
  useLatestActivitiesQuery,
} from '../../apiHooks/useLatestActivitiesQuery';
import {
  type CompanyScoreTimelineQueryParams,
  type GetCompanyScoreTimelineResponse,
  useCompanyScoreTimelineQuery,
} from '../../apiHooks/useCompanyScoreTimelineQuery';
import {
  type GetUserScoreTimelineResponse,
  type UserScoreTimelineQueryParams,
  useUserScoreTimelineQuery,
} from '../../apiHooks/useUserScoreTimelineQuery';
import { Spinner } from '../Spinner/Spinner';
import { Pagination } from '../Pagination';
import { scoreBreakdownIcons } from './utils/scoreBreakdownIcons';

/** Helper function to filter out only tracked activities **/
const filterTrackedActivities = (activities: LearnerActivity[]): LearnerActivity[] =>
  activities.filter((activity) => trackedActionsScoreTypesSet.has(activity.actionScoreType)) || [];

/** Helper function to sort and merge the user timeline by date **/
const mergeUserTimeline = (timeline?: GetUserScoreTimelineResponse): GetUserScoreTimelineResponse => {
  if (!timeline || timeline.length === 0) {
    return [];
  }

  // Sort timeline by date (ascending)
  const sortedTimeline = [...timeline].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // If only one activity exists, add a previous day entry with a zero score
  if (sortedTimeline.length === 1 && sortedTimeline[0]) {
    const onlyActivity = sortedTimeline[0];
    const activityDate = new Date(onlyActivity.date);
    const previousDay = new Date(activityDate);
    previousDay.setUTCDate(activityDate.getUTCDate() - 1);
    return [{ learnerId: '', items: [], date: previousDay.toISOString(), totalScore: 0 }, onlyActivity];
  }

  return sortedTimeline;
};

/** Helper function to merge the company's timeline with the user's dates **/
export const mergeCompanyTimeline = (
  companyTimeline?: GetCompanyScoreTimelineResponse,
  userTimeline?: GetUserScoreTimelineResponse,
): GetCompanyScoreTimelineResponse => {
  if (!companyTimeline || !userTimeline) {
    return [];
  }

  // Create a map from the company timeline keyed by normalized date (ISO string)
  const companyMap = new Map(companyTimeline.map((entry) => [new Date(entry.date).toISOString(), entry]));

  // Collect unique dates from the user timeline
  const uniqueDates = new Set<string>();
  userTimeline.forEach((activity) => {
    const dateObj = new Date(activity.date);
    dateObj.setUTCHours(0, 0, 0, 0);
    uniqueDates.add(dateObj.toISOString());
  });

  // For each unique date, use the corresponding company data if it exists; otherwise, provide default values.
  const mergedTimeline = Array.from(uniqueDates).map(
    (date) =>
      companyMap.get(date) ?? {
        date,
        organizationId: '',
        totalScore: 0,
        items: [],
      },
  );

  // Sort the timeline by date ascending
  mergedTimeline.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return mergedTimeline;
};

export type ActivityOverviewWidgetParams =
  | (LatestActivitiesQueryParams & UserScoreTimelineQueryParams & CompanyScoreTimelineQueryParams)
  | undefined;

type ActivityOverviewWidgetProps = {
  params?: ActivityOverviewWidgetParams;
  onTotalScoreReceived?: (totalScore?: number) => void;
};

const ITEMS_PER_PAGE: number = 5 as const;

/** Component **/
export const ActivityOverviewWidget: FC<ActivityOverviewWidgetProps> = ({
  params,
  onTotalScoreReceived,
}: ActivityOverviewWidgetProps) => {
  const [latestActivitiesQueryParams, setLatestActivitiesQueryParams] = useState<LatestActivitiesQueryParams>({
    page: {
      skip: params?.page?.skip || 0,
      take: params?.page?.take || ITEMS_PER_PAGE,
    },
    sort: {
      sortDirection: params?.sort?.sortDirection || 'descending',
      property: params?.sort?.property || 'createdTime',
    },
    scoreBreakdowns: params?.scoreBreakdowns || [],
  });

  const { data: latestActivitiesResponse, isFetching: isFetchingLatestActivity } =
    useLatestActivitiesQuery(latestActivitiesQueryParams);
  const { data: companyScoreTimelineResponse, isFetching: isFetchingCompanyScoreTimeline } =
    useCompanyScoreTimelineQuery(params);
  const { data: userScoreTimelineResponse, isFetching: isFetchingUserScoreTimeline } =
    useUserScoreTimelineQuery(params);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [langCode] = useLanguageContext();

  useEffect(() => {
    if (latestActivitiesResponse && onTotalScoreReceived) {
      onTotalScoreReceived(latestActivitiesResponse.totalScore);
    }
  }, [latestActivitiesResponse, onTotalScoreReceived]);

  const getLatestActivitiesData = (response: GetLatestActivitiesResponse | undefined) => {
    if (!response) {
      return [];
    }

    if ('learnerId' in response) {
      return response.learnerActivities;
    }

    return response.values;
  };
  const latestActivities = useMemo(
    () => filterTrackedActivities(getLatestActivitiesData(latestActivitiesResponse)),
    [latestActivitiesResponse],
  );

  const sortedUserScoreTimeline = useMemo(
    () => mergeUserTimeline(userScoreTimelineResponse),
    [userScoreTimelineResponse],
  );

  const sortedCompanyScoreTimeline = useMemo(
    () => mergeCompanyTimeline(companyScoreTimelineResponse, sortedUserScoreTimeline),
    [companyScoreTimelineResponse, sortedUserScoreTimeline],
  );

  const columns: Column<LearnerActivity>[] = [
    {
      header: <NamedContentEntryText container="label" refKey="behaviors" subKey="behavior" />,
      valueSelector: (activity: LearnerActivity) => (
        <BehaviorContainer>
          {scoreBreakdownIcons[activity.scoreBreakdown]}
          <NamedContentEntryText container="span" refKey="behaviors" subKey={activity.scoreBreakdown} />
        </BehaviorContainer>
      ),
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="recentActivity" />,
      valueSelector: (activity: LearnerActivity) => (
        <NamedContentEntryText container="span" refKey="activityStatus" subKey={activity.actionScoreType} />
      ),
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="date" />,
      valueSelector: (activity: LearnerActivity) =>
        new Intl.DateTimeFormat(langCode).format(new Date(activity.createdTime)),
      width: '100px',
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="points" />,
      valueSelector: (activity: LearnerActivity) => activity.score && <Coin value={activity.score} />,
      width: 'min-content',
      alignment: 'right',
    },
  ];

  const theme = useTheme();

  const handlePageChange = (page: number) => {
    setLatestActivitiesQueryParams((prev) => ({
      ...prev,
      page: {
        take: ITEMS_PER_PAGE,
        skip: (page - 1) * ITEMS_PER_PAGE,
      },
    }));
    setCurrentPage(page);
  };

  return (
    <>
      {isFetchingLatestActivity || isFetchingCompanyScoreTimeline || isFetchingUserScoreTimeline ? (
        <LoadingSpinner>
          <Spinner size="48px" color={theme.colors.primary} />
        </LoadingSpinner>
      ) : (
        <>
          <ActivityOverviewChartContainer>
            <ActivityOverviewChart data={sortedUserScoreTimeline} companyAverage={sortedCompanyScoreTimeline} />
          </ActivityOverviewChartContainer>
          <Table data={latestActivities} columns={columns} />
          {latestActivitiesResponse && 'values' in latestActivitiesResponse && (
            <Pagination
              listSize={latestActivitiesResponse.total}
              currentPage={currentPage}
              perPage={ITEMS_PER_PAGE}
              pages={Math.ceil(latestActivitiesResponse.total / ITEMS_PER_PAGE)}
              onChange={handlePageChange}
            />
          )}
        </>
      )}
    </>
  );
};

const ActivityOverviewChartContainer = styled.div`
  padding-left: 4px;
`;

const LoadingSpinner = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BehaviorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
