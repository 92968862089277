import { Modal } from '../../Modal';
import { type FC, useState } from 'react';
import { withErrorBoundaries } from '../../../lib/decorators/withErrorBoundaries';
import { ActivityOverviewWidget, type ActivityOverviewWidgetParams } from '../../Activity/ActivityOverviewWidget';
import { CardErrorView } from '../../ErrorView/CardErrorView';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import { Icon, IconType } from '../../Props/Icon/Icon';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';

type ActivityLogModalProps = {
  isOpen: boolean;
  onClose: () => void;
  params?: ActivityOverviewWidgetParams;
};

export const ActivityLogModal: FC<ActivityLogModalProps> = ({ isOpen, onClose, params }: ActivityLogModalProps) => {
  const { ActivityOverviewWidget_WithErrorBoundary } = withErrorBoundaries(
    {
      ActivityOverviewWidget,
    },
    CardErrorView,
  );
  const [totalScore, setTotalScore] = useState<number | undefined>(undefined);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setTotalScore(undefined);
      }}
      hideCloseButton
    >
      <ModalHeader>
        <Title>
          <NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="activityOverview" />
        </Title>
        {totalScore && (
          <AllTimeScore>
            <NamedContentEntryText container="span" refKey="activityOverview" subKey="allTimeScore" />
            <StyledCoin>
              <span>{formatNumberWithCommas(totalScore)}</span>
              <Icon icon={IconType.COIN} width={20} height={20} />
            </StyledCoin>
          </AllTimeScore>
        )}
        <CloseButton
          onClick={() => {
            onClose();
            setTotalScore(undefined);
          }}
        >
          <Icon icon={IconType.TIMES} width={32} height={32} />
        </CloseButton>
      </ModalHeader>
      <ModalContainer>
        <ActivityOverviewWidget_WithErrorBoundary
          params={params}
          onTotalScoreReceived={(totalScore) => setTotalScore(totalScore)}
        />
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  min-width: 800px;
  padding: 16px;
`;

const ModalHeader = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.pageBackground};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading};
  color: ${({ theme }) => theme.colors.heading};
  margin-right: auto;
  font-weight: 600;
  text-transform: uppercase;
  user-select: none;
`;

const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllTimeScore = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
  gap: 8px;
`;

const StyledCoin = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  background-color: ${({ theme }) => theme.colors.panelBackground};
  color: ${({ theme }) => theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
`;
