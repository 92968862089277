import { type FC, useEffect, useState } from 'react';
import { Table } from '../Table';
import { Card } from '../Card';
import { useLeaderboardData } from './hooks/useLeaderboardData';
import { LabelledToggle } from '../Toggle';
import styled, { useTheme } from 'styled-components';
import {
  type LeaderboardPagedResults,
  LeaderboardTypeParam,
  useLeaderboardQuery,
} from '../../apiHooks/useLeaderboardQuery';
import type { LeaderboardUser } from '../../lib/types/LeaderboardUser';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { useMediaQuery } from '../../lib/hooks/useMediaQuery';
import { NoLeaderboardDataCard } from './components/NoLeaderboardDataCard';

type LeaderboardUIProps = {
  isManager?: boolean;
};

export const LeaderboardUI: FC<LeaderboardUIProps> = ({ isManager }: LeaderboardUIProps) => {
  const [selectedScopeFilter, setSelectedScopeFilter] = useState<LeaderboardTypeParam | undefined>(
    LeaderboardTypeParam.TEAM,
  );
  const { columns, mobileColumns, scopeFilterLabels } = useLeaderboardData(selectedScopeFilter);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data: meData } = useMeQuery();
  const {
    data: leaderboardData,
    isFetching,
    refetch,
  } = useLeaderboardQuery({
    type: selectedScopeFilter || LeaderboardTypeParam.TEAM,
    mode: 'top',
    count: 10,
    isManager,
  });

  useEffect(() => {
    refetch();
  }, [selectedScopeFilter, refetch]);

  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false);
    }
  }, [isFetching]);

  const getTableData = (data: LeaderboardUser[] | LeaderboardPagedResults | undefined): LeaderboardUser[] => {
    if (Array.isArray(data)) {
      return data;
    }
    return data?.values || [];
  };

  const tableData = getTableData(leaderboardData);

  const onScopeFilterChange = (index: number) => {
    setIsLoading(true);
    setSelectedScopeFilter(scopeFilterLabels[index]?.value);
  };

  // const onDateFilterChange = (index: number) => {
  //   console.log(`Selected date: ${dateFilterLabels[index]?.value}`);
  // };

  return (
    <Card>
      <FiltersBar>
        <LabelledToggle
          labels={scopeFilterLabels}
          onSelect={(selectedIndex: number) => onScopeFilterChange(selectedIndex)}
        />
        {/*<LabelledToggle*/}
        {/*  labels={dateFilterLabels}*/}
        {/*  onSelect={(selectedIndex: number) => onDateFilterChange(selectedIndex)}*/}
        {/*/>*/}
      </FiltersBar>
      <Table
        data={tableData}
        columns={isMobile ? mobileColumns : columns}
        roundedRows={!isMobile}
        hideSeparator={!isMobile}
        hideHeaders={isMobile}
        rowColorSelector={(data: LeaderboardUser) =>
          data.learnerId === meData?.learnerId ? theme.colors.pageBackground : 'unset'
        }
        renderZeroState={() => <NoLeaderboardDataCard />}
        isLoading={isFetching || isLoading}
      />
    </Card>
  );
};

const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
