import type { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Spread } from '../utility/Spread';

export const Card: React.FC<
  PropsWithChildren<{ title?: ReactNode; rightElem?: ReactNode; className?: string; withShadow?: boolean }>
> = ({ title, rightElem, children, className, withShadow }) => (
  <CardContainer className={className} $withShadow={withShadow}>
    {(title || rightElem) && (
      <TitleContainer>
        <Spread>
          {typeof title === 'string' ? <h2>{title}</h2> : (title ?? <span>&nbsp;</span>)}
          {typeof rightElem === 'string' ? <span>{rightElem}</span> : (rightElem ?? <span>&nbsp;</span>)}
        </Spread>
      </TitleContainer>
    )}
    {typeof children === 'string' ? <span>{children}</span> : children}
  </CardContainer>
);

const CardContainer = styled.div<{ $withShadow?: boolean }>`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: ${({ theme, $withShadow }) => ($withShadow ? theme.shadow.light : 'unset')};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  color: ${({ theme }) => theme.colors.heading};
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  text-transform: uppercase;
`;
