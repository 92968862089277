import type { UserLevels } from '../lib/enums/userLevels';
import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useTeamSecuritySnapshotQuery() {
  return useLPSuspenseQuery<TeamSecuritySnapshot>(['team-overview'], '/manager/teamOverview');
}
type TeamSecuritySnapshot = {
  organisationCyberIq: OrganisationCyberIq;
  teamCyberIq: TeamCyberIq;
};

type OrganisationCyberIq = {
  cyberIqScore: number;
  cyberIQLevelAverage: number;
};

type TeamCyberIq = OrganisationCyberIq & {
  cyberIqLevelBrackets: CyberIqLevelBrackets[];
};

export type CyberIqLevelBrackets = {
  cyberIqLevel: UserLevels;
  learnerCount: number;
  learnerPercentage: number;
  label?: string;
};
