import { useLPQuery } from '../lib/hooks/useLPQuery';
import type { ScoreBreakdownCategories } from './useScoreBreakdownByUserQuery';
import { type GroupingType, TimeRange } from './useUserScoreTimelineQuery';

export type CompanyScoreTimelineQueryParams = {
  groupingType: GroupingType;
  timeRange: TimeRange;
  scoreBreakdowns: ScoreBreakdownCategories[];
};

export const useCompanyScoreTimelineQuery = (params?: CompanyScoreTimelineQueryParams) => {
  return useLPQuery<GetCompanyScoreTimelineResponse>(
    ['activities-company', params],
    '/activities/company/scoreTimeline',
    {},
    undefined,
    params ? { method: 'POST', json: params } : undefined,
  );
};

export type GetCompanyScoreTimelineResponse = {
  items: CompanyScoreTimelineItem[];
  date: string;
  organizationId: string;
  totalScore: number;
}[];

export type CompanyScoreTimelineItem = {
  date: string;
  scoreBreakdown: ScoreBreakdownCategories;
};
