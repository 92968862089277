import { LabelledToggle } from '../Toggle';
import { Table } from '../Table';
import styled, { useTheme } from 'styled-components';
import type { LeaderboardUser } from '../../lib/types/LeaderboardUser';
import {
  type LeaderboardPagedResults,
  LeaderboardTypeParam,
  useLeaderboardQuery,
} from '../../apiHooks/useLeaderboardQuery';
import { useLeaderboardWidgetData } from './hooks/useLeaderboardWidgetData';
import { useEffect, useState, type FC } from 'react';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { NoLeaderboardDataCard } from './components/NoLeaderboardDataCard';
import LinkButton from '../Button/LinkButton/LinkButton';
import { Icon, IconType } from '../Props/Icon/Icon';
import { LeaderboardModal } from './LeaderboardModal';

type LeaderboardWidgetUIProps = {
  isManager?: boolean;
};

export const LeaderboardWidgetUI: FC<LeaderboardWidgetUIProps> = ({ isManager = false }) => {
  const [selectedScopeFilter, setSelectedScopeFilter] = useState<LeaderboardTypeParam | undefined>(
    LeaderboardTypeParam.TEAM,
  );
  const { columns, scopeFilterLabels } = useLeaderboardWidgetData(isManager, selectedScopeFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const theme = useTheme();

  const { data: meData } = useMeQuery();
  const {
    data: leaderboardWidgetData,
    isFetching,
    refetch,
  } = useLeaderboardQuery({
    type: selectedScopeFilter || LeaderboardTypeParam.TEAM,
    mode: 'top',
    count: 5,
    isManager,
  });

  useEffect(() => {
    refetch();
  }, [selectedScopeFilter, refetch]);

  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false);
    }
  }, [isFetching]);

  const getTableData = (data: LeaderboardUser[] | LeaderboardPagedResults | undefined): LeaderboardUser[] => {
    if (Array.isArray(data)) {
      return data;
    }
    return data?.values || [];
  };

  const tableData = getTableData(leaderboardWidgetData);

  const onScopeFilterChange = (index: number) => {
    console.log(`Selected scope: ${scopeFilterLabels[index]?.value}`);
    setSelectedScopeFilter(scopeFilterLabels[index]?.value);
    setIsLoading(true);
  };

  return (
    <>
      <Card
        title={
          <CardTitleContainer>
            <NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="leaderboard" />
            <LeftSection>
              {'total' in leaderboardWidgetData && (
                <UserCount>
                  <Icon width={16} height={16} icon={IconType.USER_COUNT} fill="none" />
                  <span>{leaderboardWidgetData.total}</span>
                </UserCount>
              )}
              <LinkButton
                title={<NamedContentEntryText container={'span'} refKey="leaderboard" subKey="viewMore" />}
                onClick={() => setShowDialog(true)}
              ></LinkButton>
            </LeftSection>
          </CardTitleContainer>
        }
      >
        <FiltersBar>
          <LabelledToggle
            labels={scopeFilterLabels}
            onSelect={(selectedIndex: number) => onScopeFilterChange(selectedIndex)}
          />
        </FiltersBar>
        <Table
          data={tableData}
          columns={columns}
          hideHeaders
          roundedRows
          hideSeparator
          rowColorSelector={(data: LeaderboardUser) =>
            data.learnerId === meData?.learnerId ? theme.colors.pageBackground : 'unset'
          }
          renderZeroState={() => <NoLeaderboardDataCard />}
          isLoading={isLoading || isFetching}
        />
      </Card>
      {showDialog && (
        <LeaderboardModal isManager={isManager} isOpen={showDialog} onClose={() => setShowDialog(false)} />
      )}
    </>
  );
};

const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 24px;
  color: ${({ theme }) => theme.colors.normal};
`;

const UserCount = styled.div`
  display: flex;
  gap: 8px;
`;
