import styled from 'styled-components';
import { LeaderboardWidgetUI } from '../Leaderboard/LeaderboardWidgetUI';
import { SecuritySnapshotUI } from '../SecuritySnapshot/SecuritySnapshotUI';
import { TrainingWidget } from '../Activity/TrainingWidget';
import { ScoreBreakdown } from '../ScoreBreakdown/ScoreBreakdown';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { CardErrorView } from '../ErrorView/CardErrorView';
import { ScoreBreakdownErrorCard } from '../ErrorView/ScoreBreakdownErrorCard';
import { TrainingWidgetErrorCard } from '../ErrorView/TrainingWidgetErrorCard';
import { LeaderboardWidgetErrorCard } from '../ErrorView/LeaderboardWidgetErrorCard';
import { Card } from '../Card';

const { SecuritySnapshotUI_WithErrorBoundary } = withErrorBoundaries({ SecuritySnapshotUI }, CardErrorView);
const { ScoreBreakdown_WithErrorBoundary } = withErrorBoundaries({ ScoreBreakdown }, ScoreBreakdownErrorCard);
const { TrainingWidget_WithErrorBoundary } = withErrorBoundaries({ TrainingWidget }, TrainingWidgetErrorCard);
const { LeaderboardWidgetUI_WithErrorBoundary } = withErrorBoundaries(
  { LeaderboardWidgetUI },
  LeaderboardWidgetErrorCard,
);

export const Overview: React.FC = () => {
  return (
    <Layout>
      <Column>
        <Card>
          <SecuritySnapshotUI_WithErrorBoundary />
          <ScoreBreakdown_WithErrorBoundary />
        </Card>
      </Column>
      <Column>
        <LeaderboardWidgetUI_WithErrorBoundary />
        <TrainingWidget_WithErrorBoundary />
      </Column>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
