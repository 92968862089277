import type { FC } from 'react';
import { Card } from '../Card';
import styled from 'styled-components';
import errorCardImage from '../ScoreBreakdown/assets/ErrorCard.png';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

export const ScoreBreakdownErrorCard: FC = () => {
  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="scoreBreakdown" />}>
      <div style={{ position: 'relative' }}>
        <CardContainer>
          <img width={96} height={96} src={errorCardImage} alt="Error" />
          <HeadingText>
            <NamedContentEntryText refKey={'scoreBreakdownErrorCard'} container={'span'} subKey={'heading'} />
          </HeadingText>
          <DescriptionText>
            <NamedContentEntryText refKey={'scoreBreakdownErrorCard'} container={'span'} subKey={'description'} />
          </DescriptionText>
        </CardContainer>
      </div>
    </Card>
  );
};

const CardContainer = styled(Card)`
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
  box-shadow: unset;
`;

const HeadingText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
`;

const DescriptionText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;
