import { TeamBehaviorTypes } from '../../../lib/enums/teamBehaviorsTypes';
import TrainingCompletedIcon from '../assets/AllTrainingsCompleted.svg';
import CyberSecurityKnoaledgeIcon from '../assets/BuildKnowledge.svg';
import HighEngagmentIcon from '../assets/HighEngagement.svg';
import SimulatedPhishingIcon from '../assets/NoClickPhishing.svg';
import ReportPhishingIcon from '../assets/ReportPhishing.svg';
import { ScoreBreakdownType } from '../../../lib/types/TeamBehavior';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
// import DefendAttacksIcon from '../assets/DefendPhishing.svg';
// import ExternalStorageIcon from '../assets/NoExternalUSB.svg';
// import SecureBrowsingIcon from '../assets/SecureDevice.svg';
// import MalwareInfectionIcon from '../assets/MalwareInfection.svg';
// import PasswordManagerIcon from '../assets/PasswordManager.svg';
// import LatestUpdatesIcon from '../assets/LatestUpdates.svg';
// import MaintainSecureDeviceIcon from '../assets/SecureDevice.svg';
// import SensitiveDataIcon from '../assets/SensitiveData.svg';

const getTeamBehaviorDetails = (type: TeamBehaviorTypes | ScoreBreakdownType) => {
  switch (type) {
    case ScoreBreakdownType.Completion:
      return {
        icon: <TrainingCompletedIcon />,
        displayName: <NamedContentEntryText container={'span'} refKey="behaviors" subKey="completion" />,
      };
    case ScoreBreakdownType.Knowledge:
      return {
        icon: <CyberSecurityKnoaledgeIcon />,
        displayName: <NamedContentEntryText container={'span'} refKey="behaviors" subKey="knowledge" />,
      };
    case ScoreBreakdownType.Engagement:
      return {
        icon: <HighEngagmentIcon />,
        displayName: <NamedContentEntryText container={'span'} refKey="behaviors" subKey="engagement" />,
      };
    case ScoreBreakdownType.PhishingClicks:
      return {
        icon: <SimulatedPhishingIcon />,
        displayName: <NamedContentEntryText container={'span'} refKey="behaviors" subKey="phishingClicks" />,
      };
    case ScoreBreakdownType.PhishingReports:
      return {
        icon: <ReportPhishingIcon />,
        displayName: <NamedContentEntryText container={'span'} refKey="behaviors" subKey="phishingReports" />,
      };

    // TODO: only 5 behaviors supported on first iteration from BE, uncomment this when support from BE available

    // case TeamBehaviorTypes.DefendAttacks:
    //   return <DefendAttacksIcon />;
    // case TeamBehaviorTypes.ExternalStorage:
    //   return <ExternalStorageIcon />;
    // case TeamBehaviorTypes.SecureBrowsing:
    //   return <SecureBrowsingIcon />;
    // case TeamBehaviorTypes.MalwareInfection:
    //   return <MalwareInfectionIcon />;
    // case TeamBehaviorTypes.PasswordManager:
    //   return <PasswordManagerIcon />;
    // case TeamBehaviorTypes.LatestUpdates:
    //   return <LatestUpdatesIcon />;
    // case TeamBehaviorTypes.MaintainSecureDevice:
    //   return <MaintainSecureDeviceIcon />;
    // case TeamBehaviorTypes.SensitiveData:
    //   return <SensitiveDataIcon />;
    default:
      return null;
  }
};
export default getTeamBehaviorDetails;
