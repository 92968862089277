import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';
import type { TeamBehavior } from '../lib/types/TeamBehavior';

export const useTeamBehaviorsQuery = () => {
  const queryKey: string[] = ['TeamBehaviorsQuery'];
  const endpoint = `/manager/teamBehaviours`;
  return useLPSuspenseQuery<TeamBehaviorData>(queryKey, endpoint);
};

export type TeamBehaviorData = {
  teamBehaviours: TeamBehavior[];
  totalMembers: number;
};
