import type { FC } from 'react';
import CoinSVG from './assets/Coin.svg';
import RulerOfMassesSVG from './assets/RulerOfMasses.svg';
import TheTalkinatorSVG from './assets/TheTalkinator.svg';
import GlobeTrotterSVG from './assets/GlobeTrotter.svg';
import PhantomPosterSVG from './assets/PhantomPoster.svg';
import ProgressArrowUpSVG from './assets/ProgressArrowUp.svg';
import ProgressArrowDownSVG from './assets/ProgressArrowDown.svg';
import AINeuralShareSVG from './assets/AINeuralShare.svg';
import BrainSVG from './assets/Brain.svg';
import ClipboardCheckSVG from './assets/ClipboardCheck.svg';
import CPUProcessorRefreshSVG from './assets/CPUProcessorRefresh.svg';
import DashLoadingHearthSVG from './assets/DashLoadingHearth.svg';
import MalwareSVG from './assets/Malware.svg';
import MouseCursorSVG from './assets/MouseCursor.svg';
import ShieldSearchSVG from './assets/ShieldSearch.svg';
import WebPageSVG from './assets/WebPage.svg';
import UserLoadSVG from './assets/UserLoad.svg';
import ChevronRightSVG from './assets/ChevronRight.svg';
import ChevronDownSVG from './assets/ChevronDown.svg';
import SettingsWheelSVG from './assets/SettingsWheel.svg';
import TimesSVG from './assets/Times.svg';
import CalendarSVG from './assets/Calendar.svg';
import GoalFlagSVG from './assets/GoalFlag.svg';
import { type SVGRSvg } from '../../../lib/types/SVGRSvg';
import CornerDownRightArrowSVG from './assets/CornerDownRightArrow.svg';
import RedirectLinkSVG from './assets/RedirectLink.svg';
import InfoTooltipSVG from './assets/InfoTooltip.svg';
import UsersCountSVG from './assets/UsersCount.svg';
import ClockSVG from './assets/Clock.svg';
import StarMedalSVG from './assets/StarMedal.svg';
import GraduateCapSVG from './assets/GraduateCap.svg';
import NoBadgesSVG from './assets/NoBadges.svg';
import AlertTriangleSVG from './assets/AlertTriangle.svg';
import DownloadSVG from './assets/Download.svg';
import DotsMenuIconSVG from './assets/DotsMenuIcon.svg';
import FilterSVG from './assets/Filter.svg';
import BehaviorCompletionSVG from './assets/BehaviorCompletion.svg';
import BehaviorKnowledgeSVG from './assets/BehaviorKnowledge.svg';
import BehaviorEngagementSVG from './assets/BehaviorEngagement.svg';
import BehaviorPhishingClicksSVG from './assets/BehaviorPhishingClicks.svg';
import BehaviorPhishingReportsSVG from './assets/BehaviorPhishingReports.svg';

type IconProps = {
  icon: string;
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
};

export const Icon: FC<IconProps> = ({
  icon,
  width = '24',
  height = '24',
  fill = 'currentColor',
  className = '',
}: IconProps) => {
  const SelectedIcon = IconMapper[icon];

  if (!SelectedIcon) {
    console.error(`Icon "${icon}" not found.`);
    return null;
  }

  return <SelectedIcon width={width} height={height} fill={fill} className={className} />;
};

export enum IconType {
  COIN = 'Coin',
  BADGE_RULER_OF_MASSES = 'RulerOfMasses',
  BADGE_THE_TALKINATOR = 'TheTalkinator',
  BADGE_GLOBE_TROTTER = 'GlobeTrotter',
  BADGE_PHANTOM_POSTER = 'PhantomPoster',
  PROGRESS_ARROW_UP = 'ProgressArrowUp',
  PROGRESS_ARROW_DOWN = 'ProgressArrowDown',
  AI_NEURAL_SHARE = 'AINeuralShare',
  BRAIN = 'Brain',
  CLIPBOARD_CHECK = 'ClipboardCheck',
  CPU_PROCESSOR_REFRESH = 'CPUProcessorRefresh',
  DASH_LOADING_HEARTH = 'DashLoadingHearth',
  MALWARE = 'Malware',
  MOUSE_CURSOR = 'MouseCursor',
  SHIELD_SEARCH = 'ShieldSearch',
  WEB_PAGE = 'WebPage',
  USER_LOAD = 'UserLoad',
  CHEVRON_RIGHT = 'ChevronRight',
  CHEVRON_DOWN = 'ChevronDown',
  SETTINGS_WHEEL = 'SettingsWheel',
  CORNER_DOWN_RIGHT_ARROW = 'CornerDownRightArrow',
  TIMES = 'Times',
  CALENDAR = 'Calendar',
  GOAL_FLAG = 'GoalFlag',
  REDIRECT_LINK = 'RedirectLink',
  INFO_TOOLTIP = 'InfoTooltip',
  USER_COUNT = 'UsersCount',
  CLOCK = 'Clock',
  STAR_MEDAL = 'StarMedal',
  GRADUATE_CAP = 'GraduateCap',
  NO_BADGES = 'NoBadges',
  ALERT_TRIANGLE = 'AlertTriangle',
  DOWNLOAD = 'Download',
  DOTS_MENU_ICON = 'DotsMenuIcon',
  FILTER = 'Filter',
  BEHAVIOR_COMPLETION = 'BehaviorCompletion',
  BEHAVIOR_KNOWLEDGE = 'BehaviorKnowledge',
  BEHAVIOR_ENGAGEMENT = 'BehaviorEngagement',
  BEHAVIOR_PHISHING_CLICKS = 'BehaviorPhishingClicks',
  BEHAVIOR_PHISHING_REPORTS = 'BehaviorPhishingReports',
}

const IconMapper: Record<string, SVGRSvg> = {
  [IconType.COIN]: CoinSVG,
  [IconType.BADGE_RULER_OF_MASSES]: RulerOfMassesSVG,
  [IconType.BADGE_THE_TALKINATOR]: TheTalkinatorSVG,
  [IconType.BADGE_GLOBE_TROTTER]: GlobeTrotterSVG,
  [IconType.BADGE_PHANTOM_POSTER]: PhantomPosterSVG,
  [IconType.PROGRESS_ARROW_UP]: ProgressArrowUpSVG,
  [IconType.PROGRESS_ARROW_DOWN]: ProgressArrowDownSVG,
  [IconType.AI_NEURAL_SHARE]: AINeuralShareSVG,
  [IconType.BRAIN]: BrainSVG,
  [IconType.CLIPBOARD_CHECK]: ClipboardCheckSVG,
  [IconType.CPU_PROCESSOR_REFRESH]: CPUProcessorRefreshSVG,
  [IconType.DASH_LOADING_HEARTH]: DashLoadingHearthSVG,
  [IconType.MALWARE]: MalwareSVG,
  [IconType.MOUSE_CURSOR]: MouseCursorSVG,
  [IconType.SHIELD_SEARCH]: ShieldSearchSVG,
  [IconType.WEB_PAGE]: WebPageSVG,
  [IconType.USER_LOAD]: UserLoadSVG,
  [IconType.CHEVRON_RIGHT]: ChevronRightSVG,
  [IconType.CHEVRON_DOWN]: ChevronDownSVG,
  [IconType.SETTINGS_WHEEL]: SettingsWheelSVG,
  [IconType.CORNER_DOWN_RIGHT_ARROW]: CornerDownRightArrowSVG,
  [IconType.TIMES]: TimesSVG,
  [IconType.CALENDAR]: CalendarSVG,
  [IconType.GOAL_FLAG]: GoalFlagSVG,
  [IconType.REDIRECT_LINK]: RedirectLinkSVG,
  [IconType.INFO_TOOLTIP]: InfoTooltipSVG,
  [IconType.USER_COUNT]: UsersCountSVG,
  [IconType.CLOCK]: ClockSVG,
  [IconType.STAR_MEDAL]: StarMedalSVG,
  [IconType.GRADUATE_CAP]: GraduateCapSVG,
  [IconType.NO_BADGES]: NoBadgesSVG,
  [IconType.ALERT_TRIANGLE]: AlertTriangleSVG,
  [IconType.DOWNLOAD]: DownloadSVG,
  [IconType.DOTS_MENU_ICON]: DotsMenuIconSVG,
  [IconType.FILTER]: FilterSVG,
  [IconType.BEHAVIOR_COMPLETION]: BehaviorCompletionSVG,
  [IconType.BEHAVIOR_KNOWLEDGE]: BehaviorKnowledgeSVG,
  [IconType.BEHAVIOR_ENGAGEMENT]: BehaviorEngagementSVG,
  [IconType.BEHAVIOR_PHISHING_CLICKS]: BehaviorPhishingClicksSVG,
  [IconType.BEHAVIOR_PHISHING_REPORTS]: BehaviorPhishingReportsSVG,
};
