import React, { type ReactNode, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TableHeader } from './components/TableHeader';
import { Cell, RowContainer, TableRow } from './components/TableRow';
import { Expander } from '../Expander';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Spinner } from '../Spinner/Spinner';

export type Column<T> = {
  header: string | ReactNode;
  valueSelector: (data: T) => ReactNode;
  alignment?: 'left' | 'center' | 'right';
  width?: string;
  minWidth?: string;
  columnClickDisabled?: boolean;
};

export type TableProps<T> = {
  data: T[];
  columns: Column<T>[];
  maxHeight?: `${number}px`;
  hideHeaders?: boolean;
  rowColorSelector?: (data: T, index?: number) => string;
  onRowClick?: (data: T) => void;
  expandedElementSelector?: (data: T, index?: number) => ReactNode;
  roundedRows?: boolean;
  hideSeparator?: boolean;
  renderZeroState?: () => ReactNode;
  isLoading?: boolean;
};

export const Table = <T,>({
  data,
  columns,
  hideHeaders,
  maxHeight,
  rowColorSelector,
  onRowClick,
  expandedElementSelector,
  roundedRows,
  hideSeparator,
  renderZeroState,
  isLoading,
}: TableProps<T>) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const gridTemplateColumns = columns.map((c) => `minmax(${c.minWidth ?? 0}, ${c.width ?? '1fr'})`).join(' ');

  const handleExpandRow = (index: number) => {
    if (expandedElementSelector) {
      setExpandedRowIndex((prev) => (prev === index ? null : index));
    }
  };
  const theme = useTheme();

  const getZeroStateElement = () => {
    if (renderZeroState) {
      return <ZeroStateContainer>{renderZeroState()}</ZeroStateContainer>;
    }

    return (
      <RowContainer>
        <FullWidthContainer>
          <NamedContentEntryText container="span" refKey="table" subKey="noItems" />
        </FullWidthContainer>
      </RowContainer>
    );
  };

  return (
    <TableContainer>
      <StyledTable gridTemplateColumns={gridTemplateColumns} maxHeight={maxHeight}>
        {!hideHeaders && <TableHeader columns={columns} />}
        <>
          <Body>
            {isLoading ? (
              <LoadingSpinnerWrapper>
                <LoadingSpinnerContainer>
                  <Spinner color={theme.colors.primary} size="48px" />
                </LoadingSpinnerContainer>
              </LoadingSpinnerWrapper>
            ) : (
              <>
                {!data.length && getZeroStateElement()}
                {data.map((item: T, index: number) => (
                  <React.Fragment key={index}>
                    <TableRow
                      columns={columns}
                      rowData={item}
                      rowColor={rowColorSelector?.(item, index)}
                      onRowClick={onRowClick ? () => onRowClick(item) : undefined}
                      onExpandRow={expandedElementSelector ? () => handleExpandRow(index) : undefined}
                      isExpanded={expandedRowIndex === index}
                      roundedRows={roundedRows}
                      hideSeparator={hideSeparator}
                    />
                    {expandedElementSelector && (
                      <RowContainer>
                        <ExpanderContainer isExpanded={expandedRowIndex === index}>
                          <Expander isOpen={expandedRowIndex === index}>
                            {expandedElementSelector(item, index)}
                          </Expander>
                        </ExpanderContainer>
                      </RowContainer>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </Body>
        </>
      </StyledTable>
    </TableContainer>
  );
};
const LoadingSpinnerContainer = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingSpinnerWrapper = styled.tr`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 250px;
`;

const StyledTable = styled.table.withConfig({
  shouldForwardProp: (prop) => prop !== 'gridTemplateColumns' && prop !== 'maxHeight',
})<{ gridTemplateColumns: string; maxHeight?: string }>`
  display: grid;
  border-collapse: collapse;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  background-color: ${({ theme }) => theme.colors.panelBackground};
  ${({ maxHeight }) =>
    maxHeight
      ? `
    max-height: ${maxHeight};
    overflow-y: auto;
    `
      : ''}
`;

const Body = styled.tbody`
  display: contents;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const FullWidthContainer = styled(Cell)`
  grid-column: 1 / -1;
  align-items: center;
  justify-content: center;
`;

const ExpanderContainer = styled.td.withConfig({
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded?: boolean }>`
  grid-column: 1 / -1;
  padding: 0 0 ${({ isExpanded }) => (isExpanded ? '8px' : 0)} 16px;
  background-color: ${({ theme }) => theme.colors.pageBackground};
  transition: padding-bottom 250ms ease-in-out;
`;

const ZeroStateContainer = styled.div`
  grid-column: 1 / -1;
`;
