import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Card } from '../Card';
import { useClickOutside } from '../../lib/hooks/useOnClickOutside';
import SignOutIcon from './assets/sign-out.svg';
import { SettingsMenu } from './components/SettingsMenu';
import { NavLinks } from './components/NavLinks';
import { User } from './components/User';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Button } from '../Button';
import { UserAvatar } from './components/UserAvatar';
import { SettingsButton } from './components/SettingsButton';
import { useNavigate } from 'react-router';
import { MobileTopBarMenu } from './components/MobileTopBarMenu';
import { Icon, IconType } from '../Props/Icon/Icon';
import { SwitchViewMenu, ViewOptions } from './components/SwitchViewMenu';

export type Page = {
  name: React.ReactNode;
  path: string;
};

export type TopBarUIUser = {
  name: string | null;
  role: 'learner' | 'manager' | null;
  /**
   * this is a RAW base64 encoded binary blob with content type image/png, not a data URL
   */
  avatar: string | null;
};

export type TopBarUIProps = {
  pages: Page[];
  user: TopBarUIUser;
  logoUrl: string;
  onSignOut: () => void;
  managerView?: boolean;
};
export const TopBarUI: React.FC<TopBarUIProps> = ({ pages, user, logoUrl, onSignOut: onLogoff, managerView }) => {
  const [selectedMenu, setSelectedMenu] = useState<'user' | 'switchView'>();
  const handleHide = useCallback(() => {
    setSelectedMenu(undefined);
  }, []);
  const theme = useTheme();

  const clickOutsideRef = useClickOutside<HTMLDivElement>(handleHide);
  const menuItems = useMemo(
    () => [
      {
        element: <SignOutIcon />,
        label: <NamedContentEntryText container={'span'} refKey="menuSystem" subKey="signOut" />,
        action: () => onLogoff(),
      },
    ],
    [onLogoff],
  );

  const showManageViewButton = useMemo(() => user.role === 'manager', [user.role]);
  const navigate = useNavigate();

  const onSwitchView = (view: ViewOptions) => {
    if (view === ViewOptions.MANAGER && !managerView) {
      navigate('manager');
    }

    if (view === ViewOptions.USER && managerView) {
      navigate('..');
    }

    handleHide();
  };

  return (
    <div ref={clickOutsideRef}>
      <TopBarOuter>
        <Logo src={logoUrl} alt="Logo" />
        {showManageViewButton && (
          <ManagerViewContainer>
            <Button
              size="small"
              padding="6px 8px"
              backgroundColor={managerView ? theme.colors.managerPrimary : undefined}
              onClick={() => setSelectedMenu((prev) => (prev === 'switchView' ? undefined : 'switchView'))}
            >
              <ManagerViewButtonContainer>
                <ManagerViewButtonText>
                  <ManagerViewButtonHighlight>
                    <NamedContentEntryText
                      refKey="menuSystem"
                      container="span"
                      subKey={managerView ? 'managerView' : 'userView'}
                    />
                  </ManagerViewButtonHighlight>
                </ManagerViewButtonText>
                <Icon icon={IconType.CHEVRON_DOWN} />
              </ManagerViewButtonContainer>
            </Button>
            <SwitchViewMenu selectedMenu={selectedMenu} onSwitch={onSwitchView} />
          </ManagerViewContainer>
        )}
        <Separator />
        <NavLinks pages={pages} isManagerView={managerView} />
        <MobileSeparator />
        <ResponsiveMenuSection>
          <User name={user.name} role={user.role} />
          <UserAvatar user={user} />
          <SettingsButton isOpen={selectedMenu === 'user'} setSelectedMenu={setSelectedMenu} />
          <SettingsMenu selectedMenu={selectedMenu} user={user} menuItems={menuItems} />
        </ResponsiveMenuSection>
        <MobileTopBarMenu pages={pages} managerView={managerView} onSignOut={onLogoff} />
      </TopBarOuter>
    </div>
  );
};

const TopBarOuter = styled(Card).attrs({ withShadow: true })`
  padding: 0 20px;
  position: relative;
  border-radius: 39px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  height: 56px;

  @media (max-width: 768px) {
    border-radius: 0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
`;

export const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const Logo = styled.img`
  max-height: 40px;
  object-fit: contain;
`;

const Separator = styled.div`
  width: 1px;
  height: 56px;
  background: ${({ theme }) => theme.colors.tableBorder};
`;

const MobileSeparator = styled(Separator)`
  height: 40px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ManagerViewContainer = styled.div`
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ManagerViewButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  svg path {
    stroke: #fff;
  }
`;

const ManagerViewButtonText = styled.span`
  margin-left: 10px;
`;

const ManagerViewButtonHighlight = styled.span`
  font-weight: 500;
`;

const ResponsiveMenuSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;
