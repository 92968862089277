import { type FC } from 'react';
import { Table } from '../Table';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { useMediaQuery } from '../../lib/hooks/useMediaQuery';
import { useTeamBehaviorsQuery } from '../../apiHooks/useTeamBehaviorsQuery';
import { useTeamBehaviorsData } from './hooks/useTeamBehaviorsData';
import styled from 'styled-components';
import { Icon, IconType } from '../Props/Icon/Icon';
import { Tooltip } from '../Tooltip';
import { BehaviorListMobile } from './components/BehaviorListMobile/BehaviorListMobile';

export const TeamBehaviorsUI: FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const behaviorData = useTeamBehaviorsQuery();
  const totalTeamMembersCount = behaviorData.data?.totalMembers || 0;
  const { columns } = useTeamBehaviorsData(totalTeamMembersCount);

  return (
    <Card
      title={
        <TitleContainer>
          <NamedContentEntryText container="span" refKey="managerSectionTitles" subKey="teamBehaviors" />
          <Tooltip
            offset={15}
            content={
              <TooltipContainer>
                <NamedContentEntryText container="span" refKey="managerTeamBehaviors" subKey="tooltip" />
              </TooltipContainer>
            }
            placement="top"
          >
            <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
          </Tooltip>
        </TitleContainer>
      }
    >
      <TeamSize>
        <NamedContentEntryText container="span" refKey="managerTeamBehaviors" subKey="teamSize" />:
        <TeamCount>{totalTeamMembersCount}</TeamCount>
      </TeamSize>

      {isMobile ? (
        <BehaviorListMobile data={behaviorData.data.teamBehaviours} totalTeamMembersCount={totalTeamMembersCount} />
      ) : (
        <Table
          data={behaviorData.data.teamBehaviours}
          columns={columns}
          hideSeparator={true}
          hideHeaders={isMobile}
          isLoading={false}
        />
      )}
    </Card>
  );
};
const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TeamSize = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const TeamCount = styled.span`
  font-weight: bold;
`;
const TooltipContainer = styled.div`
  padding: 10px;
  width: 338px;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: #0c0c0d1a 2px 2px 8px;
  color: ${({ theme }) => theme.colors.normal};
`;
