import { Overview } from '../components/Overview';
import { NamedContentEntryText } from '../components/ContentEntryText/NamedContentEntryText';
import type { MenuPage } from './MenuPage';

/**
 * An array of objects representing the pages in the top bar menu.
 * Each object contains the following properties:
 * - `path`: A string representing the URL path of the page.
 * - `name`: A string representing the display name of the page.
 * - `element`: A React element representing the component to be rendered for the page.
 */
export const userTopbarMenuPages: MenuPage[] = [
  {
    path: 'overview',
    name: <NamedContentEntryText container="span" refKey="menuSystem" subKey="overview" />,
    element: <Overview />,
  },
];
