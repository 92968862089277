import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import BankCardSearchIcon from '../../../../assets/BankCardSearch.svg';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useNamedContentEntryText } from '../../../../lib/hooks/contentEntry/useNamedContentEntryText';
import type { GetCompanyScoreTimelineResponse } from '../../../../apiHooks/useCompanyScoreTimelineQuery';
import type { GetUserScoreTimelineResponse } from '../../../../apiHooks/useUserScoreTimelineQuery';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

/** Utility Functions **/
const getBasePowerOfTen = (value: number) => Math.pow(10, Math.floor(Math.log10(value)));

const getNextStep = (value: number, factors = [1, 2, 5, 10]) => {
  const base = getBasePowerOfTen(value);
  return base * (factors.find((factor) => factor * base >= value) || 1);
};

const computeMaxY = (values: number[]) => {
  const maxValue = Math.max(...values.map((val) => val || 0));
  return getNextStep(maxValue);
};

/** Plugin: Draws hover rectangle over active index **/
const createHoverEffectPlugin = (cellSize = 50) => ({
  id: 'hoverRectangle',
  afterDraw: (chart: ChartJS) => {
    const { ctx, chartArea, tooltip } = chart;
    if (tooltip?.getActiveElements().length) {
      const activeElement = tooltip.getActiveElements()[0];
      if (activeElement && chart.data.labels && chart.scales.x) {
        const index = activeElement.index;
        if (index === 0 || index === chart.data.labels.length - 1) {
          return;
        }
        const xValue = chart.data.labels[index];
        const xPos = chart.scales.x.getPixelForValue(xValue as number);

        ctx.save();
        ctx.fillStyle = 'rgba(211, 211, 211, 0.5)';
        ctx.fillRect(
          xPos - cellSize / 2, // Center rectangle around data point
          chartArea.top,
          cellSize,
          chartArea.bottom - chartArea.top,
        );
        ctx.restore();
      }
    }
  },
});

type ActivityOverviewChartProps = {
  data: GetUserScoreTimelineResponse;
  companyAverage: GetCompanyScoreTimelineResponse;
};

/** Main Chart Implementation **/
const ActivityOverviewChartImpl = ({ data, companyAverage }: ActivityOverviewChartProps) => {
  const learnerPointsLabel = useNamedContentEntryText({
    refKey: 'activityOverview',
    subKey: 'yourPoints',
  });
  const companyAverageLabel = useNamedContentEntryText({
    refKey: 'activityOverview',
    subKey: 'companyAverage',
  });

  // Process dates and scores
  const orderedDates = data.map((item) => item.date);
  const userScores = data.map((item) => item.totalScore || 0);
  const companyScoreMap = new Map(companyAverage.map((item) => [item.date, item.totalScore]));
  const companyScores = orderedDates.map((date) => companyScoreMap.get(date) ?? null);

  // Calculate the maximum y value for the chart
  const combinedScores = [...companyScores.filter((score): score is number => score !== null), ...userScores];
  const maxY = combinedScores.length === 0 ? 100 : computeMaxY(combinedScores);

  // Format x-axis labels based on the number of unique months
  const uniqueMonths = new Set(
    orderedDates.map((date) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth()}`;
    }),
  );
  const labels = orderedDates.map((date) => {
    if (uniqueMonths.size > 9) {
      return new Date(date).toLocaleDateString('en-US', { month: 'short' });
    } else {
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
    }
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: learnerPointsLabel,
        data: userScores,
        borderColor: '#6600FF',
        backgroundColor: '#6600FF',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: companyAverageLabel,
        data: companyScores,
        borderColor: '#6DABFC',
        backgroundColor: '#6DABFC',
        borderWidth: 2,
        borderDash: [10, 10],
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 7,
          boxHeight: 7,
          padding: 15,
          color: '#596180',
          font: { size: 12, lineHeight: 0.5 },
        },
      },
    },
    scales: {
      x: {
        border: { dash: [2, 2] },
        ticks: {
          color: '#888EAA',
          autoSkip: true,
        },
      },
      y: {
        beginAtZero: true,
        max: maxY,
        ticks: {
          color: '#888EAA',
          autoSkip: true,
        },
        border: { dash: [2, 2] },
      },
    },
    layout: {
      padding: { bottom: 10, left: 10 },
    },
  } as never;

  return (
    <div style={{ width: '100%', height: '200px' }}>
      <Line data={chartData} options={options} plugins={[createHoverEffectPlugin()]} />
    </div>
  );
};

/** Wrapper Component with Zero State Overlay **/
export const ActivityOverviewChart = ({ data, companyAverage }: ActivityOverviewChartProps) => (
  <ChartContainer>
    {data.length === 0 && (
      <ZeroStateOverlay>
        <BankCardSearchIcon />
        <NamedContentEntryText container="span" refKey="chart" subKey="chartNoData" />
      </ZeroStateOverlay>
    )}
    <ActivityOverviewChartImpl data={data} companyAverage={companyAverage} />
  </ChartContainer>
);

const ChartContainer = styled.div`
  position: relative;
`;

const ZeroStateOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 1;
  pointer-events: none;
  padding-bottom: 48px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;

  > svg {
    width: 48px;
    height: 48px;
  }
`;
