import type { FC } from 'react';
import styled from 'styled-components';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Icon, IconType } from '../Props/Icon/Icon';
import { LeaderboardUI } from './LeaderboardUI';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { CardErrorView } from '../ErrorView/CardErrorView';
import { Modal } from '../Modal';
import { ModalSize } from '../Modal/Modal';

type LeaderboardModalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isManager?: boolean;
};

export const LeaderboardModal: FC<LeaderboardModalModalProps> = ({
  isOpen,
  onClose,
  isManager,
}: LeaderboardModalModalProps) => {
  const { LeaderboardUI_WithErrorBoundary } = withErrorBoundaries(
    {
      LeaderboardUI,
    },
    CardErrorView,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton size={ModalSize.LARGE}>
      <ModalHeader>
        <Title>
          <NamedContentEntryText container="span" refKey="menuSystem" subKey="leaderboard" />
        </Title>
        <CloseButton onClick={onClose}>
          <Icon icon={IconType.TIMES} width={16} height={16} />
        </CloseButton>
      </ModalHeader>
      <ModalContainer>
        <LeaderboardUI_WithErrorBoundary isManager={isManager} />
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 16px;
`;

const ModalHeader = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
  text-transform: uppercase;
  user-select: none;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;
