import type { ReactNode } from 'react';
import { ScoreBreakdownCategories } from '../../../apiHooks/useScoreBreakdownByUserQuery';
import { Icon, IconType } from '../../Props/Icon/Icon';

export const scoreBreakdownIcons: Record<ScoreBreakdownCategories, ReactNode> = {
  [ScoreBreakdownCategories.COMPLETION]: (
    <Icon icon={IconType.BEHAVIOR_COMPLETION} fill="transparent" width={20} height={20} />
  ),
  [ScoreBreakdownCategories.ENGAGEMENT]: (
    <Icon icon={IconType.BEHAVIOR_ENGAGEMENT} fill="transparent" width={20} height={20} />
  ),
  [ScoreBreakdownCategories.KNOWLEDGE]: (
    <Icon icon={IconType.BEHAVIOR_KNOWLEDGE} fill="transparent" width={20} height={20} />
  ),
  [ScoreBreakdownCategories.PHISHING_CLICKS]: (
    <Icon icon={IconType.BEHAVIOR_PHISHING_CLICKS} fill="transparent" width={20} height={20} />
  ),
  [ScoreBreakdownCategories.PHISHING_REPORTS]: (
    <Icon icon={IconType.BEHAVIOR_PHISHING_REPORTS} fill="transparent" width={20} height={20} />
  ),
};
