import { animated, to, useSpring } from '@react-spring/web';
import { transparentize } from 'polished';
import { useCallback, useEffect, useRef, useState, type ComponentProps, useMemo } from 'react';
import { NavLink, matchPath, resolvePath, useLocation, useResolvedPath } from 'react-router';
import styled, { useTheme } from 'styled-components';
import { type Page } from '../TopBarUI';

export const NavLinks: React.FC<{ className?: string; pages: Page[]; isManagerView?: boolean }> = ({
  className,
  pages,
  isManagerView = false,
}) => {
  const resolvedPath = useResolvedPath('', { relative: 'route' });
  const location = useLocation();

  const pagesExt = pages.map((page) => {
    const path = resolvePath(page.path, resolvedPath.pathname);
    const m = matchPath(path.pathname, location.pathname);
    return { ...page, active: !!m };
  });

  const activePageName = useMemo(() => {
    const active = pagesExt.find((page) => page.active);
    return active?.name;
  }, [pagesExt]);

  const theme = useTheme();

  const [linkbarPos, setLinkbarPos] = useState<{ x: number; width: number; color: string }>({
    x: 0,
    width: 0,
    color: isManagerView ? theme.colors.managerPrimary : theme.colors.anchor,
  });

  const { linkbarMidPointX, linkbarColor } = useSpring({
    linkbarMidPointX: linkbarPos.x + linkbarPos.width / 2,
    linkbarColor: linkbarPos.color,
    config: { tension: 300, friction: 30 },
  });

  const { linkbarWidth } = useSpring({ linkbarWidth: linkbarPos.width, config: { tension: 200, friction: 20 } });

  const containerRef = useRef<HTMLDivElement>(null);
  const activeRef = useRef<HTMLAnchorElement>();
  const linkbarPadding = 12;

  const updateLinkbarState = useCallback(
    (el: HTMLAnchorElement, isActive: boolean) => {
      if (!containerRef.current) {
        return;
      }

      const container = containerRef.current;
      const elRect = el.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const xPosition = elRect.left - containerRect.left;
      const width = elRect.width;

      setLinkbarPos({
        x: xPosition,
        width: width + linkbarPadding,
        color: isActive
          ? isManagerView
            ? theme.colors.managerPrimary
            : theme.colors.anchor
          : transparentize(0.5, theme.colors.normal),
      });
    },
    [theme.colors, isManagerView],
  );

  const activeLinkHandler = useCallback(
    (el: HTMLAnchorElement) => {
      activeRef.current = el;

      updateLinkbarState(el, true);
    },
    [updateLinkbarState],
  );

  return (
    <>
      <ActiveLinkLabel>{activePageName}</ActiveLinkLabel>
      <LinksContainer
        className={className}
        ref={containerRef}
        onMouseLeave={() => activeRef.current && updateLinkbarState(activeRef.current, true)}
      >
        {pagesExt.map(({ name, path, active }) => (
          <NLinkWrapper
            key={path}
            to={path}
            isActive={active}
            onActive={activeLinkHandler}
            onMouseEnter={(e) => updateLinkbarState(e.currentTarget, active)}
            style={{ cursor: active ? 'default' : 'pointer' }}
            $isManagerView={isManagerView}
          >
            {name}
          </NLinkWrapper>
        ))}
        <animated.div
          style={{
            width: linkbarWidth,
            position: 'absolute',
            bottom: 0,
            left: to([linkbarMidPointX, linkbarWidth], (m, w) => m - (w + linkbarPadding) / 2),
            height: 3,
            backgroundColor: linkbarColor,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        />
      </LinksContainer>
    </>
  );
};

const NLink = styled(NavLink)<{ $isManagerView: boolean }>`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.normal};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 500;
  height: 100%;
  margin: 0;
  padding: 0;

  &.active {
    color: ${({ theme, $isManagerView }) => ($isManagerView ? theme.colors.managerPrimary : theme.colors.anchor)};
  }
`;

const NLinkWrapper: React.FC<
  ComponentProps<typeof NLink> & { isActive: boolean; onActive: (el: HTMLAnchorElement) => void }
> = ({ children, isActive, onActive, ...props }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isActive && linkRef.current) {
      onActive(linkRef.current);
    }
  }, [isActive, onActive]);

  useEffect(() => {
    if (!linkRef.current) {
      return;
    }

    const v = linkRef.current;
    const obs = new ResizeObserver(() => {
      if (!isActive) {
        return;
      }
      onActive(v);
    });

    obs.observe(v);

    return () => {
      obs.disconnect();
    };
  }, [isActive, onActive]);

  return (
    <NLink {...props} ref={linkRef}>
      {children}
    </NLink>
  );
};

const LinksContainer = styled.div`
  display: flex;
  gap: 2em;
  position: relative;
  height: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ActiveLinkLabel = styled.span`
  color: ${({ theme }) => theme.colors.waningText};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  margin-left: auto;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
