import { type FC } from 'react';
import styled from 'styled-components';
import GreenArrowUp from '../../../../assets/GreenArrowUp.svg';
import RedArrowDown from '../../../../assets/RedArrowDown.svg';
import NeutralState from '../../../../assets/Line.svg';
import { BehaviorType } from '../../enum/BehaviorType';
import type { TeamBehavior } from '../../../../lib/types/TeamBehavior';

export const BehaviorBarMap = {
  [BehaviorType.WEAK]: {
    backgroundColor: '#E93354',
    icon: <GreenArrowUp />,
  },
  [BehaviorType.NEUTRAL]: {
    backgroundColor: '#B6BEDC',
    icon: <NeutralState />,
  },
  [BehaviorType.STRONG]: {
    backgroundColor: '#3ABF87',
    icon: <RedArrowDown />,
  },
};

type BehaviorBarProps = {
  teamBehaviorDetails: TeamBehavior;
  totalCount: number;
};

export const BehaviorBar: FC<BehaviorBarProps> = ({ teamBehaviorDetails, totalCount }) => {
  const isZeroState =
    teamBehaviorDetails.learners.strong + teamBehaviorDetails.learners.weak + teamBehaviorDetails.learners.neutral ===
    0;
  const hasStrongBehavior = teamBehaviorDetails.learners.strong > 0;
  const hasWeakBehavior = teamBehaviorDetails.learners.weak > 0;
  const hasOnlyNeutralBehavior = teamBehaviorDetails.learners.weak + teamBehaviorDetails.learners.strong === 0;

  const getBehaviorTypeProgressBarWithSteps = () => {
    return (
      <ProgressBarWrapper>
        <ProgressBar>
          {isZeroState ? (
            <ProgressFill
              isZeroState={isZeroState}
              progress={50}
              backgroundColor={BehaviorBarMap[BehaviorType.NEUTRAL].backgroundColor}
            />
          ) : (
            Object.entries(teamBehaviorDetails.learners)
              .reverse()
              .map(([key, value], index) => {
                const behaviorMap = BehaviorBarMap[key as BehaviorType];
                const progress = (value / totalCount) * 100;
                if (progress > 0) {
                  return (
                    <ProgressFill
                      isZeroState={isZeroState}
                      type={key as BehaviorType}
                      hasStrongBehavior={hasStrongBehavior}
                      hasWeakBehavior={hasWeakBehavior}
                      hasOnlyNeutralBehavior={hasOnlyNeutralBehavior}
                      key={index}
                      progress={progress}
                      backgroundColor={behaviorMap.backgroundColor}
                    />
                  );
                }
              })
          )}
        </ProgressBar>
      </ProgressBarWrapper>
    );
  };
  return (
    <BehaviorBarContainer>
      <TeamMemberCount>{teamBehaviorDetails.learners.weak}</TeamMemberCount>
      <BaseBarWrapper>
        <BaseBar />
        {getBehaviorTypeProgressBarWithSteps()}
      </BaseBarWrapper>
      <TeamMemberCount>{teamBehaviorDetails.learners.strong}</TeamMemberCount>
    </BehaviorBarContainer>
  );
};
const BehaviorBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const BaseBarWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
`;

const BaseBar = styled.div`
  position: absolute;
  min-height: 2px;
  width: 100%;
  background-color: #d5daee;
  z-index: 1;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: visible;
  z-index: 2;
`;

const ProgressBar = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  flex-grow: 1;
  height: 8px;
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 16px;
  }
`;

const ProgressFill = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'progress' && prop !== 'backgroundColor',
})<{
  progress: number;
  backgroundColor?: string;
  type?: BehaviorType;
  isZeroState: boolean;
  hasStrongBehavior?: boolean;
  hasWeakBehavior?: boolean;
  hasOnlyNeutralBehavior?: boolean;
}>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : BehaviorBarMap[BehaviorType.NEUTRAL].backgroundColor};
  transition: width 0.3s ease-in-out;
  border-radius: ${({ type, isZeroState, hasStrongBehavior, hasWeakBehavior, hasOnlyNeutralBehavior }) =>
    isZeroState || hasOnlyNeutralBehavior
      ? '8px'
      : type === BehaviorType.STRONG
        ? '0 8px 8px 0'
        : type === BehaviorType.WEAK
          ? '8px 0 0 8px'
          : hasStrongBehavior && hasWeakBehavior
            ? 'none'
            : hasWeakBehavior
              ? '0 8px 8px 0'
              : hasStrongBehavior
                ? '8px 0 0 8px'
                : 'none'};
`;

const TeamMemberCount = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin: 0 8px;
  flex-shrink: 0;
`;
