import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export enum CampaignActionType {
  SUCCESS = 'success',
  NEUTRAL = 'neutral',
  FAIL = 'fail',
}

export enum ScoreBreakdownCategories {
  COMPLETION = 'completion',
  PHISHING_REPORTS = 'phishingReports',
  PHISHING_CLICKS = 'phishingClicks',
  ENGAGEMENT = 'engagement',
  KNOWLEDGE = 'knowledge',
}

export type AggregatedScoreBreakdown = {
  scoreBreakdown: ScoreBreakdownCategories;
  score: number;
  globalScore: number;
  assetsUrl: string;
};

export type CampaignAction = {
  campaignId: string;
  date: string;
  result: CampaignActionType;
};

export type ScoreBreakdownContentEntryKey =
  | 'scoreBreakdownCompletion'
  | 'scoreBreakdownEngagement'
  | 'scoreBreakdownKnowledge'
  | 'scoreBreakdownPhishingReports'
  | 'scoreBreakdownPhishingClicks';

export type ScoreBreakdownData = {
  scoreBreakdownAggregations: AggregatedScoreBreakdown[];
  campaignActions: CampaignAction[];
};

export function useScoreBreakdownByUserQuery() {
  return useLPSuspenseQuery<ScoreBreakdownData>([`scoreBreakdowns/me/summary`], `/scoreBreakdowns/me/summary`);
}
