import type { FC } from 'react';
import styled from 'styled-components';
import { BehaviorTypeHeader } from '../BehaviorTypeHeader/BehaviorTypeHeader';
import { BehaviorType } from '../../enum/BehaviorType';
import { BehaviorBar } from '../BehaviorBar/BehaviorBar';
import type { TeamBehavior } from '../../../../lib/types/TeamBehavior';
import MoreInfoButton from '../../../../assets/MoreInfoInactive.svg';

type BehaviorListMobileProps = {
  data: TeamBehavior[];
  totalTeamMembersCount: number;
};

export const BehaviorListMobile: FC<BehaviorListMobileProps> = ({ data, totalTeamMembersCount }) => {
  return (
    <BehaviorListContainer>
      <LegendContainer>
        <BehaviorTypeHeader type={BehaviorType.WEAK} />
        <BehaviorTypeHeader type={BehaviorType.NEUTRAL} />
        <BehaviorTypeHeader type={BehaviorType.STRONG} />
      </LegendContainer>
      <BehaviorList>
        {data.map((behaviorDetails: TeamBehavior, index: number) => {
          return (
            <BehaviorDetails key={behaviorDetails.behaviour}>
              <TitleContainer>
                <span>
                  {index}. {behaviorDetails.behaviour}
                </span>
                <MoreInfoButton />
              </TitleContainer>
              <BarContainer>
                <BehaviorBar totalCount={totalTeamMembersCount} teamBehaviorDetails={behaviorDetails} />
              </BarContainer>
            </BehaviorDetails>
          );
        })}
      </BehaviorList>
    </BehaviorListContainer>
  );
};

const BehaviorListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BehaviorList = styled.div`
  width: 100%;
  display: contents;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const BehaviorDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 2px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #292b33;
  font-weight: 500;
`;

const BarContainer = styled.div`
  position: relative;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
