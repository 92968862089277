import React from 'react';
import styled from 'styled-components';

export type LinkButtonProps = {
  title: string | React.ReactNode;
  to?: string;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
};

const LinkButton: React.FC<LinkButtonProps> = ({ onClick, title, className, icon }) => {
  return (
    <StyledLink onClick={onClick} className={className}>
      <Content>
        <Text>{title}</Text>
        {icon}
      </Content>
    </StyledLink>
  );
};

export default LinkButton;

const StyledLink = styled.a`
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-decoration: underline;
`;

const Text = styled.span`
  font-weight: 400;
  text-transform: none;
`;
