import type { FC } from 'react';
import styled from 'styled-components';
import { UserLevelDetails } from '../../../lib/enums/userLevels';
import type { CyberIqLevelBrackets } from '../../../apiHooks/useTeamSecuritySnapshotQuery';

type LevelsBreakdownDetailsProps = {
  details: CyberIqLevelBrackets[];
};

export const LevelsBreakdownDetails: FC<LevelsBreakdownDetailsProps> = ({ details }) => {
  return (
    <LevelsBreakdownDetailsContainer>
      <HeaderTitle>
        {/* TODO: translation */}
        <LevelsTitle>Levels breakdown:</LevelsTitle>
        <TeamCountTitle>Team Members</TeamCountTitle>
      </HeaderTitle>
      <LevelDetailsContainer>
        {details.map((detail: CyberIqLevelBrackets) => {
          const userLevelDetailsMap = UserLevelDetails[detail.cyberIqLevel];
          return (
            <LevelDetail key={detail.cyberIqLevel}>
              <LeftSection>
                <LevelChip background={userLevelDetailsMap.color}>{userLevelDetailsMap.level}</LevelChip>
                <span>{userLevelDetailsMap.name}</span>
              </LeftSection>
              <RightSection>
                <TeamMemberCount>{detail.learnerCount}</TeamMemberCount>
                <span>{detail.learnerPercentage}%</span>
              </RightSection>
            </LevelDetail>
          );
        })}
      </LevelDetailsContainer>
    </LevelsBreakdownDetailsContainer>
  );
};
const LevelsBreakdownDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 400px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: #0c0c0d1a 2px 2px 8px;
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`;

const LevelsTitle = styled.span`
  color: ${({ theme }) => theme.colors.strong};
`;

const TeamCountTitle = styled.span`
  color: ${({ theme }) => theme.colors.heading};
`;

const LevelDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LevelDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  font-weight: 500;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightSection = styled.div`
  display: flex;
  gap: 16px;
`;

const LevelChip = styled.div<{ background: string }>`
  display: flex;
  padding: 2px 4px;
  color: white;
  background-color: ${({ background }) => background};
  border-radius: 10px;
  width: 60px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`;
const TeamMemberCount = styled.span`
  color: ${({ theme }) => theme.colors.strong};
`;
