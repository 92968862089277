import styled from 'styled-components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

export const ViewActivityLogTooltip = () => {
  return (
    <TooltipContainer>
      <Text>
        <NamedContentEntryText refKey="scoreBreakdownTooltips" container="span" subKey="generalInfo" />
      </Text>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: ${({ theme }) => theme.shadow.light};
  padding: 8px;
  width: max-content;
  max-width: 350px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  outline: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.normal};
  text-align: start;
`;
