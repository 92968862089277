import React from 'react';
import DownloadButton from '../DownloadButton';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useManagerExportActivityLogQuery } from '../../../../apiHooks/useManagerExportActivityLogQuery';
import { convertToCSV } from '../utils/convertToCSV';
import { downloadCSV } from '../utils/downloadCSV';

const ExportActivityLogButton: React.FC = () => {
  const { data: activityLogList } = useManagerExportActivityLogQuery();

  const handleExportUsers = async () => {
    if (activityLogList) {
      const mappedActivityLogList = activityLogList.map(({ trainings, ...activityLog }) => ({
        ...activityLog,
        assignedTrainings: trainings?.assigned || 0,
        completedTrainings: trainings?.completed || 0,
      }));
      const csv = convertToCSV(mappedActivityLogList);
      downloadCSV(csv, 'team-activity-log.csv');
    }
  };

  return (
    <DownloadButton
      onClick={handleExportUsers}
      text={<NamedContentEntryText container="span" refKey="table" subKey="exportActivityLogCsv" />}
    ></DownloadButton>
  );
};

export default ExportActivityLogButton;
