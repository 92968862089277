import { useMemo } from 'react';
import type { Column } from '../../Table/Table';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import type { TeamBehavior } from '../../../lib/types/TeamBehavior';
import MoreInfoButton from '../../../assets/MoreInfoInactive.svg';
import { BehaviorBar } from '../components/BehaviorBar/BehaviorBar';
import { BehaviorTypeHeader } from '../components/BehaviorTypeHeader/BehaviorTypeHeader';
import { BehaviorType } from '../enum/BehaviorType';
import getTeamBehaviorDetails from '../utils/getTeamBehaviorDetails';
import { Tooltip } from '../../Tooltip';

type TeamBehaviorsData = {
  columns: Column<TeamBehavior>[];
};

export const useTeamBehaviorsData = (totalCount: number): TeamBehaviorsData => {
  return useMemo(
    () => ({
      columns: [
        {
          header: (
            <BehaviorTypeContainer>
              <BehaviorTypeHeader type={BehaviorType.WEAK} />
              <BehaviorTypeHeader type={BehaviorType.NEUTRAL} />
              <BehaviorTypeHeader type={BehaviorType.STRONG} />
            </BehaviorTypeContainer>
          ),
          valueSelector: (teamBehaviorDetails: TeamBehavior) => (
            <Tooltip
              disabled={teamBehaviorDetails.learners.neutral === 0}
              offset={15}
              content={<TooltipContainer>{teamBehaviorDetails.learners.neutral}</TooltipContainer>}
              placement="top"
              fullWidth
            >
              <BehaviorBar totalCount={totalCount} teamBehaviorDetails={teamBehaviorDetails} />
            </Tooltip>
          ),
          alignment: 'left',
          width: '8fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="managerTeamBehaviors" subKey="behaviors" />,
          valueSelector: (teamBehaviorDetails: TeamBehavior) => {
            const mappingBehaviorDetails = getTeamBehaviorDetails(teamBehaviorDetails.behaviour);
            return (
              <BehaviorTypeDetails>
                {mappingBehaviorDetails?.icon}
                <span>{mappingBehaviorDetails?.displayName}</span>
              </BehaviorTypeDetails>
            );
          },
          alignment: 'left',
          width: '6fr',
        },
        // TODO: Uncomment this after we have trend data
        // {
        //   header: <NamedContentEntryText container={'span'} refKey="managerTeamBehaviors" subKey="trend" />,
        //   valueSelector: (teamBehaviorDetails: TeamBehavior) => (
        //     <BehaviourTrend
        //       value={getBehaviorTrendPercentage(teamBehaviorDetails.trend)}
        //       type={getBehaviorTrendType(teamBehaviorDetails.trend)}
        //     />
        //   ),
        //   alignment: 'left',
        //   width: '80px',
        // },
        {
          header: <NamedContentEntryText container={'span'} refKey="managerTeamBehaviors" subKey="action" />,
          // TODO: Implement more action button once Modal ready from Design
          valueSelector: () => <MoreInfoButton />,
          alignment: 'right',
          width: '50px',
        },
      ],
    }),
    [totalCount],
  );
};

const Column = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
`;

const BehaviorTypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const BehaviorTypeDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 8px 10px;
  min-width: 40px;
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: #0c0c0d1a 2px 2px 8px;
  color: ${({ theme }) => theme.colors.normal};
`;
