import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';
import type { LeaderboardUser } from '../lib/types/LeaderboardUser';

type TopQueryParams = {
  mode: 'top';
  type: LeaderboardTypeParam;
  count: number;
  isManager?: boolean;
};

type PagedQueryParams = {
  mode: 'paged';
  type: LeaderboardTypeParam;
  skip: number;
  take: number;
  isManager?: boolean;
};

export enum LeaderboardTypeParam {
  TEAM = 'team',
  DEPARTMENT = 'department',
  COMPANY = 'company',
}

type LeaderboardQueryParams = TopQueryParams | PagedQueryParams;

export type LeaderboardPagedResults = {
  total: number;
  skip: number | null;
  take: number | null;
  values: LeaderboardUser[];
};

export const useLeaderboardQuery = (params: LeaderboardQueryParams) => {
  const { type, mode, isManager } = params;

  let queryKey: (string | LeaderboardQueryParams)[];
  let endpoint: string;

  if (mode === 'top') {
    const { count } = params;
    queryKey = ['LeaderboardQuery', params];
    endpoint =
      isManager && type === LeaderboardTypeParam.TEAM
        ? `/leaderboards/manager/${type}/top`
        : `/leaderboards/${type}/top?count=${count}`;
  } else {
    const { skip, take } = params;
    queryKey = ['LeaderboardQuery', params];
    endpoint =
      isManager && type === LeaderboardTypeParam.TEAM
        ? `/leaderboards/manager/${type}?skip=${skip}&take=${take}`
        : `/leaderboards/${type}?skip=${skip}&take=${take}`;
  }

  return useLPSuspenseQuery<LeaderboardUser[] | LeaderboardPagedResults>(queryKey, endpoint);
};
