import type { FC } from 'react';
import type { AggregatedScoreBreakdown } from '../../../apiHooks/useScoreBreakdownByUserQuery';
import styled from 'styled-components';
import { Icon, IconType } from '../../Props/Icon/Icon';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type ScoreBreakdownTooltipProps = {
  item: AggregatedScoreBreakdown;
  onClick: (item: AggregatedScoreBreakdown) => void;
};

export const ScoreBreakdownTooltip: FC<ScoreBreakdownTooltipProps> = ({
  item,
  onClick,
}: ScoreBreakdownTooltipProps) => {
  return (
    <TooltipContainer>
      <NamedContentEntryText refKey="scoreBreakdownTooltips" container="span" subKey="behaviorTotal" />
      <ScoreLabel>
        <span>{formatNumberWithCommas(item.globalScore)}</span>
        <Icon icon={IconType.COIN} width={20} height={20} />
      </ScoreLabel>
      <LinkButton onClick={() => onClick(item)}>
        <NamedContentEntryText refKey="scoreBreakdownTooltips" container="span" subKey="fullLog" />
      </LinkButton>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: ${({ theme }) => theme.shadow.light};
  color: ${({ theme }) => theme.colors.heading};
  padding: 8px;
  width: max-content;
  min-width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  outline: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;

const ScoreLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LinkButton = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.normal};
  text-decoration: underline;
  cursor: pointer;
`;
