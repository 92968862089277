import styled from 'styled-components';
import { LeaderboardWidgetUI } from '../Leaderboard/LeaderboardWidgetUI';
import { TeamSecuritySnapshotUI } from '../TeamSecuritySnapshot/TeamSecuritySnapshotUI';
import { ManagerTeamTrainingWidget } from '../Activity/ManagerTeamTrainingWidget';
import { CardErrorView } from '../ErrorView/CardErrorView';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { TeamBehaviorsUI } from '../TeamBehaviors/TeamBehaviorsUI';
import { TeamMembers } from '../TeamBehaviors/components/TeamMembers';

export const ManagerOverview: React.FC = () => {
  const {
    LeaderboardWidgetUI_WithErrorBoundary,
    TeamSecuritySnapshotUI_WithErrorBoundary,
    ManagerTeamTrainingWidget_WithErrorBoundary,
    TeamBehaviorsUI_WithErrorBoundary,
    TeamMembers_WithErrorBoundary,
  } = withErrorBoundaries(
    {
      LeaderboardWidgetUI,
      TeamSecuritySnapshotUI,
      ManagerTeamTrainingWidget,
      TeamBehaviorsUI,
      TeamMembers,
    },
    CardErrorView,
  );

  return (
    <Layout>
      <Column>
        <TeamSecuritySnapshotUI_WithErrorBoundary />
        <TeamBehaviorsUI_WithErrorBoundary />
        <TeamMembers_WithErrorBoundary />
      </Column>
      <Column>
        <LeaderboardWidgetUI_WithErrorBoundary isManager />
        <ManagerTeamTrainingWidget_WithErrorBoundary />
      </Column>
    </Layout>
  );
};
const Layout = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 768px) {
    gap: 0;
  }
`;
