import type { TeamActivityLog } from '../../../../apiHooks/useManagerExportActivityLogQuery';

type SupportedDataTypes = TeamActivityLog[];

export const convertToCSV = (dataTypeList: SupportedDataTypes) => {
  if (dataTypeList.length === 0) {
    return '';
  }
  const headers = Object.keys(dataTypeList[0] || {}).join(',');
  const rows = dataTypeList.map((item) => Object.values(item).join(',')).join('\n');
  return `${headers}\n${rows}`;
};
