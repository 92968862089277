import { useLPQuery } from '../lib/hooks/useLPQuery';
import { ScoreBreakdownCategories } from './useScoreBreakdownByUserQuery';

export const useUserScoreTimelineQuery = (params?: UserScoreTimelineQueryParams) => {
  return useLPQuery<GetUserScoreTimelineResponse>(
    ['activities-score-timeline', params],
    '/activities/scoreTimeline',
    {},
    undefined,
    params ? { method: 'POST', json: params } : undefined,
  );
};

export type GetUserScoreTimelineResponse = {
  date: string;
  items: UserScoreTimelineItem[];
  totalScore: number;
  learnerId: string;
}[];

export type UserScoreTimelineItem = {
  scoreBreakdown: ScoreBreakdownCategories;
  score: number;
};

export type UserScoreTimelineQueryParams = {
  groupingType: GroupingType;
  timeRange: TimeRange;
  scoreBreakdowns: ScoreBreakdownCategories[];
};

export enum TimeRange {
  YTD = 'YTD',
  SIX_MONTHS = 'SixMonths',
  ONE_YEAR = 'OneYear',
  TWO_YEARS = 'TwoYears',
  THIRTY_DAYS = 'ThirtyDays',
  SEVEN_DAYS = 'SevenDays',
}

export enum GroupingType {
  DAY = 'Day',
  MONTH = 'Month',
  YEAR = 'Year',
}
