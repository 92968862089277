export enum ScoreBreakdownType {
  Completion = 'Completion',
  PhishingReports = 'PhishingReports',
  PhishingClicks = 'PhishingClicks',
  Engagement = 'Engagement',
  Knowledge = 'Knowledge',
}

export type BehaviorTrend = {
  previous: number;
  current: number;
};

export type BehaviorLearnersCount = {
  strong: number;
  neutral: number;
  weak: number;
};

export type TeamBehavior = {
  behaviour: ScoreBreakdownType;
  trend: BehaviorTrend;
  learners: BehaviorLearnersCount;
};
