import { type FC, useMemo } from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../Icon/Icon';

type CoinProps = {
  value: number;
  className?: string;
};

enum CoinState {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

const getCoinState = (value: number) => {
  if (value > 0) {
    return CoinState.POSITIVE;
  }
  if (value < 0) {
    return CoinState.NEGATIVE;
  }

  return CoinState.NEUTRAL;
};

export const Coin: FC<CoinProps> = ({ value, className }: CoinProps) => {
  const coinState = getCoinState(value);
  const formattedValue = useMemo(() => {
    if (coinState === CoinState.NEUTRAL) {
      return '-';
    }

    return `${coinState === CoinState.POSITIVE ? '+' : ''}${value}`;
  }, [coinState, value]);

  return (
    <StyledCoin className={className} state={coinState}>
      <ValueText state={coinState}>{formattedValue}</ValueText>
      <Icon icon={IconType.COIN} width={20} height={20} />
    </StyledCoin>
  );
};

const StyledCoin = styled.label.withConfig({
  shouldForwardProp: (prop) => prop !== 'positive',
})<{ state: CoinState }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  border: 1px solid
    ${({ state }) => (state === CoinState.POSITIVE ? '#B6E2C6' : state === CoinState.NEGATIVE ? '#F5C6C6' : '#B6BEDC')};
  background-color: ${({ state }) =>
    state === CoinState.POSITIVE ? '#ECFDF5' : state === CoinState.NEGATIVE ? '#FFF5F5' : '#F7F8FA'};
`;

const ValueText = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'positive',
})<{ state: CoinState }>`
  color: ${({ state, theme }) =>
    state === CoinState.POSITIVE ? '#3AA66A' : state === CoinState.NEGATIVE ? '#C94A4A' : theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
`;
