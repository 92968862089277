import type { FC } from 'react';
import { Card } from '../Card';
import styled from 'styled-components';
import { Icon, IconType } from '../Props/Icon/Icon';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

export const LeaderboardWidgetErrorCard: FC = () => {
  return (
    <Card title={<NamedContentEntryText container="span" refKey="sectionTitles" subKey="leaderboard" />}>
      <div style={{ position: 'relative' }}>
        <CardContainer>
          <Icon width={24} height={24} icon={IconType.ALERT_TRIANGLE} fill="transparent" />
          <DescriptionText>
            <NamedContentEntryText container="span" refKey="leaderboard" subKey="errorCardText" />
          </DescriptionText>
        </CardContainer>
      </div>
    </Card>
  );
};

const CardContainer = styled(Card)`
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
  box-shadow: unset;
`;

const DescriptionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;
