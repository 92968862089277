import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export const useManagerExportActivityLogQuery = () => {
  const queryKey: string[] = ['ActivityLogExportQuery'];
  const endpoint = `/manager/export/teamLearners`;
  return useLPSuspenseQuery<TeamActivityLog[]>(queryKey, endpoint, undefined, undefined, { method: 'POST' });
};

export type TeamActivityLog = {
  learnerId: string;
  departmentId: string;
  levelId: string;
  cyberIqPoints: number;
  name: string;
  jobTitle: boolean;
  trainings?: {
    assigned: number;
    completed: number;
  };
};
